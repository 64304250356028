/**
 * @file I18n keys
 * @module i18n.key
 * @author Surmon <https://github.com/surmon-china>
 */

export enum LANGUAGE_KEYS {
  PAGE_HOME = 'home',
  CATEGORY_INSIGHT = 'insight',
  CATEGORY_CODE = 'code',
  PAGE_GITHUB = 'github',
  PAGE_LENS = 'lens',
  PAGE_MUSIC = 'music',
  PAGE_JOB = 'job',
  PAGE_MERCH = 'merch',
  PAGE_ABOUT = 'about',
  PAGE_FREELANCER = 'freelancer',
  PAGE_GUESTBOOK = 'guestbook',
  PAGE_APP = 'app',
  PAGE_ARCHIVE = 'archive',
  EMPTY_PLACEHOLDER = 'common-empty-placeholder',
  NOT_FOUND = 'not-found',
  BACK_TO_HOME_PAGE = 'back-to-home-page',
  GUESTBOOK_SLOGAN = 'guestbook-banner-slogan',
  PAGE_FREELANCER_SLOGAN = 'freelancer-page-banner-slogan',
  PAGE_FREELANCER_EMAIL_ME = 'freelancer-email-me',
  AD = 'ad',
  BARRAGE = 'barrage',
  FEEDBACK = 'feedback',
  TO_TOP = 'to-page-top',
  TO_BOTTOM = 'to-page-bottom',
  ORIGIN_ORIGINAL = 'original',
  ORIGIN_REPRINT = 'reprint',
  ORIGIN_HYBRID = 'hybrid',
  ACTION_ON = 'on',
  ACTION_OFF = 'off',
  APP_SLOGAN = 'app-slogan',
  MUSIC_PLACEHOLDER = 'music-placeholder',
  SEARCH_PLACEHOLDER = 'search-input-placeholder',
  HOT_ARTICLE_LIST_TITLE = 'hot-article-list-title',
  ANNOUNCEMENT_PLACEHOLDER = 'announcement-empty-placeholder',
  CATEGORY_PLACEHOLDER = 'category-empty-placeholder',
  TAG_PLACEHOLDER = 'tag-placeholder',
  ARTICLE_TITLE = 'article-title',
  ARTICLE_VIEWS = 'article-views',
  ARTICLE_PLACEHOLDER = 'article-empty-placeholder',
  ARTICLE_READ_ALL = 'read-all-article-content',
  ARTICLE_RENDERING = 'rendering',
  ARTICLE_LIST_LOADMORE = 'loadmore',
  ARTICLE_LIST_LOADING = 'loading',
  ARTICLE_LIST_NO_MORE = 'no-more',
  COMMENT_LIST_EMPTY = 'comment-list-placeholder',
  COMMENT_UPVOTE = 'comment-likes',
  COMMENT_DOWNVOTE = 'comment-dislikes',
  COMMENT_REPLY = 'reply-comment',
  COMMENT_DELETE = 'delete-comment',
  COMMENT_DELETE_CONFIRM = 'delete-comment-confirm',
  COMMENT_REPLY_CANCEL = 'cancel-reply-comment',
  COMMENT_MODERATOR = 'comment-moderator',
  COMMENT_SORT_OLD = 'oldest-comments',
  COMMENT_SORT_NEW = 'newest-comments',
  COMMENT_SORT_HOT = 'hottest-comments',
  COMMENT_POST_NAME = 'comment-author-name',
  COMMENT_POST_EMAIL = 'comment-author-email',
  COMMENT_POST_SITE = 'comment-author-site-url',
  COMMENT_POST_CONTENT = 'comment-content',
  COMMENT_POST_PLACEHOLDER = 'comment-box-placeholder',
  COMMENT_POST_ERROR_EMAIL = 'comment-submit-failed-of-email-error',
  COMMENT_POST_ERROR_URL = 'comment-submit-failed-of-site-url-error',
  COMMENT_POST_ERROR_CONTENT = 'comment-submit-failed-of-content-error',
  QUERY_PARAMS_ERROR = 'query-params-error',
  POST_ACTION_ERROR = 'post-requiest-failed-of-other-error'
}
